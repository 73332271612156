
    import { Component } from 'vue-property-decorator';
    import { catalogsListHeaders } from '@/modules/changes/shared/config';
    import CatalogForm from '@/modules/changes/components/catalogs/form.vue';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { CatalogDataRequest } from '@/modules/changes/shared/requests/catalog-data-request';
    import { catalogsModule } from '@/modules/changes/shared/state/submodules';
    import { Catalog } from '@/modules/changes/shared/models/catalog';
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { ModuleProps } from '@/shared/state/template/module-props';

    @Component({
        components: {
            CatalogForm,
            ItemsTable,
        },
    })
    export default class CatalogsList extends ListComponent<Catalog, CatalogDataRequest> {
        public headers: object[] = catalogsListHeaders;
        public actionsTypes = catalogsModule.actionsTypes;
        public mutationTypes = catalogsModule.mutationsTypes;
        public props: ModuleProps = catalogsModule.moduleProps;
        public fetchAction = this.actionsTypes.FETCH_DATA;
        public removeAction = this.actionsTypes.REMOVE_ITEM;
        public store = this.$store.state.changesState.catalogsState;
        public icons: object = {
        };

        public stripHtml(html: string) {
            const tmp = document.createElement('DIV');
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || '';
        }
    }
