
import {Component} from 'vue-property-decorator';
import {categoriesModule} from '@/modules/changes/shared/state/submodules';
import {CategoryDataRequest} from '@/modules/changes/shared/requests/category-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

@Component({
    components: {FormActions},
})

export default class CategoryForm extends FormComponent<CategoryDataRequest> {
    public actionsTypes = categoriesModule.actionsTypes;
    public store = this.$store.state.changesState.categoriesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new CategoryDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj kategorie' : 'Dodaj kategorie';
    }

    public mounted() {
        this.form = this.$refs.createCategoryForm;
    }
}
