
import {Component, Watch} from 'vue-property-decorator';
import {getChangesUnits} from '@/modules/changes/shared/services/units';
import {CatalogChange} from '@/modules/changes/shared/requests/catalog-change-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import {changesModule} from '@/modules/changes/shared/state/module';
import ItemsTable from '@/shared/components/layout/list.vue';
import {catalogsListHeadersAdd} from '../../shared/config';
import {Change} from '../../shared/models/change';

@Component({
    components: {FormActions, ItemsTable},
})
export default class CatalogDetailsAddForm extends FormComponent<CatalogChange[]> {
    public headers: object[] = catalogsListHeadersAdd;
    public actionsTypes = changesModule.actionsTypes;
    public store = this.$store.state.changesState.catalogsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public mutationTypes = changesModule.mutationsTypes;
    public items: Change[] = [];
    public units = [];
    public props: ModuleProps = changesModule.moduleProps;
    public selected: CatalogChange[] = [];

    public created() {
        //
    }

    get formTitle(): string {
        return 'Dodaj zmianę lokatorską';
    }

    public mounted() {
        this.selected = this.itemData;
        this.form = this.$refs.createDetailsForm;
        getChangesUnits().then((res) => {
            this.units = res;
        });
    }

    @Watch('itemData')
    public onItemDataChange(val: CatalogChange[]) {
        this.selected = val;
        this.updateChangePrice();
    }

    public updateSelected(item: { id: number; price: number; }) {
        const index = this.selected.findIndex((e) => e.id === item.id);
        if (index !== -1) {
            this.selected[index].price = item.price;
        }
    }

    public emit() {
        this.$emit('toParentAddForm', this.selected);
    }

    public loadItems(data: Change[]) {
        this.items = JSON.parse(JSON.stringify(data));
        this.updateChangePrice();
    }

    public updateChangePrice() {
        this.$store.state.changesState.data = this.$store.state.changesState.data.map((item: Change) => {
            const selectedChange = this.itemData.find((e) => e.id === item.id);
            if (selectedChange && selectedChange.catalog_price !== item.price) {
                item.price = selectedChange.catalog_price;
            }
            return item;
        });
    }
}
