
import {Component} from 'vue-property-decorator';
import {categoriesListHeaders} from '@/modules/changes/shared/config';
import CategoryForm from '@/modules/changes/components/categories/form.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {CategoryDataRequest} from '@/modules/changes/shared/requests/category-data-request';
import {categoriesModule} from '@/modules/changes/shared/state/submodules';
import {Category} from '@/modules/changes/shared/models/category';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';

@Component({
    components: {
        CategoryForm,
        ItemsTable,
    },
})
export default class CategoriesList extends ListComponent<Category, CategoryDataRequest> {
    public headers: object[] = categoriesListHeaders;
    public actionsTypes = categoriesModule.actionsTypes;
    public mutationTypes = categoriesModule.mutationsTypes;
    public props: ModuleProps = categoriesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.changesState.categoriesState;
}
