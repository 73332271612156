const move = (array: any[], element: any, delta: number) => {
    const index = array.indexOf(element);
    const newIndex = index + delta;
    if (newIndex < 0  || newIndex === array.length) { return; }
    const indexes = [index, newIndex].sort();
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
};

export const moveUp = (array: any[], element: any) => {
    move(array, element, -1);
};

export const moveDown = (array: any[], element: any) => {
    move(array, element, 1);
};

