
import {Component} from 'vue-property-decorator';
import {changesListHeaders} from '@/modules/changes/shared/config';
import ChangeForm from '@/modules/changes/components/changes/form.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ChangeDataRequest} from '@/modules/changes/shared/requests/change-data-request';
import {changesModule} from '@/modules/changes/shared/state/module';
import {Change} from '@/modules/changes/shared/models/change';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';

@Component({
    components: {
        ChangeForm,
        ItemsTable,
    },
})
export default class ChangesList extends ListComponent<Change, ChangeDataRequest> {
    public headers: object[] = changesListHeaders;
    public actionsTypes = changesModule.actionsTypes;
    public mutationTypes = changesModule.mutationsTypes;
    public props: ModuleProps = changesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.changesState;
}
