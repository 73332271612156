
import { Component } from 'vue-property-decorator';
import { catalogsModule } from '@/modules/changes/shared/state/submodules';
import { CatalogDataRequest } from '@/modules/changes/shared/requests/catalog-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { FormActions, Wysiwyg },
})
export default class CatalogForm extends FormComponent<CatalogDataRequest> {
    public actionsTypes = catalogsModule.actionsTypes;
    public store = this.$store.state.changesState.catalogsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new CatalogDataRequest();
    public show = true;

    get formTitle(): string {
        return this.edit ? 'Edytuj katalog' : 'Dodaj katalog';
    }

    public created() {
        //
    }

    public initForm() {
        this.show = true;
        this.form = this.$refs.createCatalogForm;
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        this.reloadWysiwyg();
        return callSuper(this, 'submitForm', e, next);
    }

    public reloadWysiwyg() {
        this.show = false;
    }
}
