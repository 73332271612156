
import { mdiPencil, mdiDelete, mdiEmail, mdiDownload, mdiArrowUp, mdiArrowDown } from '@mdi/js';
import { Component } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import { parseDate } from '@/shared/helpers';
import { Catalog } from '@/modules/changes/shared/models/catalog';
import { catalogsModule } from '../../shared/state/submodules';
import CatalogForm from '@/modules/changes/components/catalogs/form.vue';
import CatalogDetailsAddForm from '@/modules/changes/components/catalogs/details-add-form.vue';
import CatalogDetailsSendForm from '@/modules/changes/components/catalogs/details-send-form.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { changesModule } from '../../shared/state/module';
import { CatalogsState } from '../../shared/state/state/catalogs';
import { CatalogChange } from '../../shared/requests/catalog-change-data-request';
import { CatalogEmailRequest } from '@/modules/changes/shared/requests/catalog-email-request';
import { Category } from '../../shared/models/category';
import { findLastId } from '@/modules/investments/components/generator/helpers';
import { moveUp, moveDown } from '@/modules/changes/shared/helpers/helpers';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    props: {
        id: String,
        itemData: Object,
        edit: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TopToolbar,
        CatalogForm,
        CatalogDetailsAddForm,
        CatalogDetailsSendForm,
        TooltipBtn,
    },
})
export default class CatalogDetails extends DetailsComponent<Catalog> {
    public icons: object = {
        mdiPencil,
        mdiDelete,
        mdiEmail,
        mdiDownload,
        mdiArrowUp,
        mdiArrowDown,
    };

    public actionsTypes = catalogsModule.actionsTypes;
    public mutationTypes = catalogsModule.mutationsTypes;
    public changesMutationTypes = changesModule.mutationsTypes;
    public props: ModuleProps = catalogsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public store: CatalogsState = this.$store.state.changesState.catalogsState;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public parseDate = parseDate;
    public dialog: boolean = false;
    public changesBeforeEdit = [];
    public emailData: CatalogEmailRequest = new CatalogEmailRequest();
    public sendDialog: boolean = false;

    public isPermitted(action: string) {
        return this.permissionCheck(`changes.catalogs.${action}`);
    }

    public moveUp(item: CatalogChange) {
        moveUp(this.item.catalog_changes, item);
    }

    public moveDown(item: CatalogChange) {
        moveDown(this.item.catalog_changes, item);
    }

    public categoryChanges(categoryId: number | null) {
        return this.item.catalog_changes.filter(({ change }) => change.change_category_id === categoryId);
    }

    public previewPDF() {
        this.$store.dispatch(this.actionsTypes.PREVIEW, this.item).then((response) => {
            if (response && response instanceof ArrayBuffer) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Tabela' + '.pdf');
                document.body.appendChild(link);
                link.click();
            }
        });
    }

    get downloading() {
        return this.store.downloadingItem;
    }

    public download() {
        this.$store.dispatch(this.actionsTypes.DOWNLOAD, this.id).then((response) => {
            if (response && response instanceof ArrayBuffer) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Tabela ' + '.pdf');
                document.body.appendChild(link);
                link.click();
            }
        });
    }

    public saveTable() {
        // tslint:disable-next-line:variable-name
        const catalog_changes = this.item.catalog_changes.map(({ change_id, price, order }) => {
            return {
                change_id,
                price,
                order,
            };
        });

        const requestData = {
            id: this.id,
            catalog_changes,
        };

        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, requestData).then(() => {
            this.changesBeforeEdit = JSON.parse(JSON.stringify(this.item.catalog_changes));
        });
    }

    public handlerAddForm(item: any[]) {
        this.item.catalog_changes = [];
        this.item.categories = [];

        item.forEach((newChange: any): void => {
            const catalogChange = {
                change: newChange,
                change_id: newChange.id,
                id: findLastId(this.item.catalog_changes) + 1,
                change_catalog_id: 0,
                price: newChange.price || newChange.catalog_price,
                order: newChange.order,
            };
            this.item.catalog_changes.push(catalogChange);
            if (
                newChange.change_category_id > 0 &&
                !this.item.categories.map((e) => e.id).includes(newChange.change_category_id)
            ) {
                this.item.categories.push(newChange.category);
            }
        });
        this.item.categories = this.item.categories.sort((a: Category, b: Category) => (a.order > b.order ? 1 : -1));
        this.item.catalog_changes.forEach((catalogChange: CatalogChange, index: number) => {
            catalogChange.order = index + 1;
        });

        this.item.catalog_changes.sort((a: any, b: any) =>
            a.change.change_category_id > b.change.change_category_id ? 1 : -1,
        );
        this.formAddDialog = false;
    }

    public created() {
        this.fetchDetails();
    }

    public fetchCallback() {
        this.changesBeforeEdit = JSON.parse(JSON.stringify(this.item.catalog_changes));
    }

    public itemSaved() {
        this.formAddDialog = false;
        this.formDialog = false;
        this.fetchDetails();
    }

    public deleteItem(item: CatalogChange) {
        const index = this.item.catalog_changes.findIndex(({ change }) => change.id === item.change.id);
        this.item.catalog_changes.splice(index, 1);
        // tslint:disable-next-line:variable-name
        const category_id = item.change.change_category_id;
        if (category_id !== null) {
            if (
                this.item.catalog_changes.filter(({ change }) => change.change_category_id === category_id).length < 1
            ) {
                const Index = this.item.categories.findIndex((category) => category.id === category_id);
                this.item.categories.splice(Index, 1);
            }
        }
    }

    //
    public editItemToolbar() {
        this.formDialog = true;
        this.editedItem = { ...this.item };
    }

    public closeForm() {
        this.formDialog = false;
    }

    get formDialog() {
        return this.store.formDialog;
    }

    set formDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get editedItem() {
        return this.store.editedItem;
    }

    set editedItem(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, value);
    }
    //

    //
    public editAddItem() {
        this.formAddDialog = true;
        this.editedAddItem = this.item.catalog_changes.map((item) => {
            item.change.catalog_price = item.price;
            return item.change;
        });
    }

    public closeFormAdd() {
        this.formAddDialog = false;
    }

    get formAddDialog() {
        return this.store.addFormDialog;
    }

    set formAddDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_ADD_FORM_DIALOG, value);
    }

    get editedAddItem() {
        return this.store.addFormEditedItem;
    }

    set editedAddItem(value) {
        this.$store.commit(this.mutationTypes.UPDATE_ADD_FORM_EDITED, value);
    }

    //
    public openEmailFormDialog() {
        this.emailData.id = this.item.id;
        // this.emailData.sender = 'test@nxo.pl';
        this.emailData.title = this.item.name || '';
        this.emailData.content = `Dzień dobry,\nprzesyłam dokument "${this.item.name}"`;
        this.emailData.attach_catalog = true;
        this.sendDialog = true;
    }
}
