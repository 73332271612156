
import {Component} from 'vue-property-decorator';
import {catalogsModule} from '@/modules/changes/shared/state/submodules';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {CatalogEmailRequest} from '../../shared/requests/catalog-email-request';
import { callSuper } from '@/shared/helpers';


@Component({
    components: {FormActions},
})
export default class CatalogDetailsSendForm extends FormComponent<CatalogEmailRequest> {
    public id!: number;
    public actionsTypes = catalogsModule.actionsTypes;
    public store = this.$store.state.changesState.catalogsState;
    public storeAction = '';
    public requestData = new CatalogEmailRequest();
    public updateAction = this.actionsTypes.SEND_EMAIL;

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Wysłanie cennika zmian lokatorskich e-mailem ' : '';
    }

    public initForm() {
        this.form = this.$refs.updateEmailForm;
         }

    get loading() {
        return this.store.sendingItem;
    }
}
