import { isEmpty } from 'lodash';
import {InvestmentObjectGenerated} from '../../shared/models/investment-object';
import {InvestmentSubjectGenerated} from '../../shared/models/investment-subject';

export const searchTree = (object: InvestmentObjectGenerated, id: number) => {
    if (object.id === id) {
        return object;
    } else if (object.children) {
        let result: InvestmentObjectGenerated = {} as InvestmentObjectGenerated;
        for (let i = 0; isEmpty(result) && i < object.children.length; i++) {
            result = searchTree(object.children[i], id);
        }
        return result;
    }
    return {} as InvestmentObjectGenerated;
};

export const findLastId = (table: any[]) => {
    let lastId = 0;
    table.forEach((item) => {
        if (lastId < item.id) {
            lastId = item.id;
        }
    });
    return lastId;
};

export const getNameOnly = (name: string) => {
    const regexName = new RegExp(`^\\D+`);
    const match = name.match(regexName);
    const nameOnly = match && match.length ? match[0] : null;
    return nameOnly;
};

export const getIdOnly = (name: string) => {
    const regexId = new RegExp(`\\d+$`);
    const match = name.match(regexId);
    const idOnly = match && match.length ? match[0] : null;
    return Number(idOnly);
};

export const findLastIdName = (table: any[], name: string) => {
    const itemsWithTheSamename = table.filter((item) => getNameOnly(item.name) === getNameOnly(name));

    let lastId = 1;
    itemsWithTheSamename.forEach((item) => {
        const id = getIdOnly(item.name);
        if (lastId < id) {
            lastId = id;
        }
    });
    return lastId;
};

export const getRootsObjects = (table: any[]) => table.filter((item: any) => !item.parent_id);

export const getChildOrChildWithChildren = (table: any[], child: any, index: number) => {
    const childCopy = {...child};

    let childrenOfThisChild = [];

    childrenOfThisChild = table.filter((item: any) => childCopy.id === item.parent_id);

    let name = childCopy.name;

    if (name) {
        if (name.includes('{{numer_kategorii}}')) {
            name = name.replaceAll('{{numer_kategorii}}', String(index + 1));
        }

        if (name.includes('{{numer_poziomu}}')) {
            name = name.replaceAll(
                '{{numer_poziomu}}',
                childCopy.level || Number(childCopy.level) === 0 ? childCopy.level : '',
            );
        }
    }

    childCopy.name = name;

    if (childrenOfThisChild.length > 0) {
        const childrenDuplicates: any[] = [];

        const childrenWithDuplicates: any[] = [];

        childrenOfThisChild.forEach((item, childIndex) => {
            const object = {...item};

            if (!object.duplicated && object.duplications > 0) {
                let duplicates = new Array(object.duplications).fill({...object, duplicated: true});
                duplicates = duplicates.map((e, i) => {
                    const duplicate = {...e};
                    const duplicateName = object.name;
                    if (name) {
                        if (name.includes('{{numer_kategorii}}')) {
                            name = name.replaceAll('{{numer_kategorii}}', String(i + (childIndex + 2)));
                        }

                        if (name.includes('{{numer_poziomu}}')) {
                            name = name.replaceAll('{{numer_poziomu}}', duplicate.level);
                        }
                    }
                    duplicate.name = duplicateName;
                    duplicate.id = 'clone_' + findLastId(table) + '_' + i;
                    if (duplicate.investment_object_type_id === 3) {
                        duplicate.level += (i + 1);
                    }
                    delete duplicate.duplications;
                    duplicate.duplicateOf = item;
                    return duplicate;
                });
                childrenDuplicates[childIndex] = duplicates;
            }
        });

        childrenOfThisChild.forEach((item, childIndex) => {
            childrenWithDuplicates.push(item);
            if (childrenDuplicates[childIndex]) {
                childrenWithDuplicates.push(...childrenDuplicates[childIndex]);
            }
        });
        childrenOfThisChild = childrenWithDuplicates;
        childrenOfThisChild.forEach((item, childIndex) => {
            item.parent = {...childCopy};
            if (item.investment_object_type_id === 3) {
                item.level = Number(childCopy.lowestLevel) + childIndex;
            }
        });
        childCopy.children = childrenOfThisChild.map((item, i) => getChildOrChildWithChildren(table, item, i));
    }


    return childCopy;
};

export const morphSubjectVariables = (subject: InvestmentSubjectGenerated, index: number) => {
    const copySubject = {...subject};

    if (copySubject.name) {
        if (copySubject.name.includes('{{numer_przedmiotu}}')) {
            copySubject.name = copySubject.name.replaceAll('{{numer_przedmiotu}}', String(index + 1));
        }
    }

    if (copySubject.code) {
        if (copySubject.code.includes('{{numer_przedmiotu}}')) {
            copySubject.code = copySubject.code.replaceAll('{{numer_przedmiotu}}', String(index + 1));
        }
    }

    return copySubject;
};

export const getSubjectOrSubjectWithRooms = (
    subjectsTable: InvestmentSubjectGenerated[],
) =>
    subjectsTable.map((subject) => {
        return subject;
    });


export const getObjectOrObjectWithSubjects = (
    objectsTable: InvestmentObjectGenerated[],
    subjectsTable: InvestmentSubjectGenerated[],
) =>
    objectsTable.map((object) => {
        const copyObject = {...object};
        let objectSubjects = subjectsTable.filter((item) => item.investment_object_id === object.id);
        if (objectSubjects.length > 0) {
            const subjectDuplicates: any[] = [];

            const subjectsWithDuplicates: any[] = [];

            objectSubjects.forEach((item, index) => {
                const subjectData = {...item};

                if (!subjectData.duplicated && subjectData.duplications && subjectData.duplications > 0) {
                    let duplicates = new Array(subjectData.duplications).fill({...subjectData, duplicated: true});
                    duplicates = duplicates.map((e, i) => {
                        const duplicate = {...e};
                        duplicate.id = 'clone_' + findLastId(subjectsTable) + '_' + i;
                        delete duplicate.duplications;
                        duplicate.duplicateOf = item;
                        return duplicate;
                    });
                    subjectDuplicates[index] = duplicates;
                }
            });

            objectSubjects.forEach((item, index) => {
                subjectsWithDuplicates.push(item);
                if (subjectDuplicates[index]) {
                    subjectsWithDuplicates.push(...subjectDuplicates[index]);
                }
            });
            objectSubjects = subjectsWithDuplicates;
        }
        copyObject.subjects = objectSubjects.map((subject, index) => morphSubjectVariables(subject, index));
        return copyObject;
    });

export const getTreeViewObject = (
    objectsTable: any[],
    subjectsTable: InvestmentSubjectGenerated[],
) => {
    const objectTableWithSubjects = getObjectOrObjectWithSubjects(objectsTable, subjectsTable);
    let rootObjects = getRootsObjects(objectTableWithSubjects);

    const objectsDuplicates: any[] = [];

    const objectsWithDuplicates: any[] = [];

    rootObjects.forEach((item, index) => {
        const object = {...item};

        if (object.duplications > 0) {
            let duplicates = new Array(object.duplications).fill({...object, duplicated: true});
            duplicates = duplicates.map((e, i) => {
                const duplicate = {...e};
                duplicate.id = 'clone_' + findLastId(objectsTable) + '_' + i;
                duplicate.duplicateOf = item;
                return duplicate;
            });
            objectsDuplicates[index] = duplicates;
        }
    });

    rootObjects.forEach((item, index) => {
        objectsWithDuplicates.push(item);
        if (objectsDuplicates[index]) {
            objectsWithDuplicates.push(...objectsDuplicates[index]);
        }
    });

    rootObjects = objectsWithDuplicates;

    return rootObjects.map((item, index) => getChildOrChildWithChildren(objectTableWithSubjects, item, index));
};
