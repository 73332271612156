export class ChangeDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public name: string = '';
    public price: number = 0;
    public unit: string = '';
    public order: number = 0;
    public code: number = 0;
    // tslint:disable-next-line:variable-name
    public change_category_id: number = 0;
    public active: string = '';
}

