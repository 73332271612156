
import { Component } from 'vue-property-decorator';
import { changesModule } from '@/modules/changes/shared/state/module';
import { ChangeDataRequest } from '@/modules/changes/shared/requests/change-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { getChangesUnits } from '@/modules/changes/shared/services/units';
import { categoriesModule } from '../../shared/state/submodules';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import CategoryForm from '../categories/form.vue';

@Component({
    components: { FormActions, AutoCompleteWithAddNew },
    props: {
        category: Object,
    },
})
export default class ChangeForm extends FormComponent<ChangeDataRequest> {
    public actionsTypes = changesModule.actionsTypes;
    public categoriesTypes = categoriesModule.actionsTypes;
    public store = this.$store.state.changesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new ChangeDataRequest();
    public units = [];
    public categoryItems = [];
    public CategoryForm = CategoryForm;

    // data required for add new type
    public categoriesState = this.$store.state.changesState.categoriesState;
    public categoriesModule = categoriesModule;

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj liste zmian lokatorskich' : 'Dodaj zmianę lokatorską';
    }

    get nettoToBrutto() {
        if (this.itemData.price !== undefined) {
            return +(this.itemData.price * 1.23).toFixed(2);
        }
    }

    get categories() {
        return this.store.categoriesState.dataList;
    }

    public mounted() {
        this.form = this.$refs.createChangeForm;
        getChangesUnits().then((resp) => {
            this.units = resp;
        });

        this.form = this.$refs.createChangeForm;
        this.$store.dispatch(this.categoriesTypes.FETCH_DATA, { simple: true });
    }
}
